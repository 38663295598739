(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    removeEmptyPtags() {
      const pTags = $('.c-wrapper p');

      if (pTags.length) {
        pTags.each((i, el) => {
          const pTag = $(el);

          if (pTag.html().replace(/\s/g, '').length === 0) {
            pTag.remove();
          }

          if (pTag.prev('hr').length) {
            if (pTag.html().replace(/\s|&nbsp;/g, '').length === 0) {
              pTag.remove();
            }
          }
        });
      }
    },

    sidenavParentActive() {
      const sideNav = $('.c-side-nav');
      const subLinkActive = sideNav.find('ul ul .c-side-nav__link--current');

      if (subLinkActive.length) {
        const parent = subLinkActive.closest('ul').parent().addClass('c-side-nav__item--parent');
        const header = parent.closest('.c-sidebar__widget').find('.c-sidebar__header');

        parent.find('.c-side-nav__link').first().addClass('c-side-nav__link--parent');
        parent.siblings().addClass('c-side-nav__item--parent');

        if (header.length) {
          header.hide();
        }
      }
    },

    sideNavGrandchildren() {
      const grandChildren = $('.js-side-nav-items-grandchildren');

      if (grandChildren.length) {
        grandChildren.parent().on('mouseenter focusin', e => {
          const parent = $(e.currentTarget);

          parent.find('.js-side-nav-items-grandchildren').addClass('show');
        }).on('mouseleave', e => {
          const parent = $(e.currentTarget);

          parent.find('.js-side-nav-items-grandchildren').removeClass('show');
        });
      }
    },

    homePageSlider() {
      const slider = $('.js-home-page-slider');
      const playstop = $('.js-home-page-slider-container').find('.js-home-slider-playpause');
      let navSetup = false;
      let dotsSetup = false;

      if (slider.length) {
        slider.on('initialized.owl.carousel', () => {
          slider.trigger('resize.owl.carousel');

          playstop.css({opacity:1});
        });

        slider.on('changed.owl.carousel', event => {
          const self = $(event.target);

          setTimeout(() => {
            const item = self.find('.owl-item');
            const activeItem = self.find('.owl-item.active');

            if (item.length && activeItem.length) {
              item.not(activeItem).attr('aria-hidden', true);
              activeItem.attr('aria-hidden', false);
            }

            const owlNav = self.find('.owl-nav');
            const owlDots = self.find('.owl-dots');
            if (owlNav.length && !navSetup) {
              owlNav.find('.owl-prev').attr('aria-label', 'Go to previous slide');
              owlNav.find('.owl-next').attr('aria-label', 'Go to next slide');
              navSetup = true;
            }

            if (owlDots.length) {
              owlDots.find('.owl-dot').each((i, el) => {
                const dot = $(el);

                if (!dotsSetup) {
                  dot.attr('aria-label', `Go to slide ${i+1}`);
                }

                if (dot.hasClass('active')) {
                  owlDots.find('.owl-dot').not(dot).attr('aria-current', false);
                  dot.attr('aria-current', true);
                }
              });
              dotsSetup = true;
            }
          });

        //   setTimeout(() => {
        //     self.find('.c-home-slide-info-btn').attr('tabindex', '-1');
        //     self.find('.owl-item.active .c-home-slide-info-btn').removeAttr('tabindex');
        //   });
        });

        slider.owlCarousel({
          items: 1,
          nav: true,
          navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#c-icon--chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#c-icon--chevron-right"></use></svg>'],
          dots: true,
          navSpeed: 3000,
          dotsSpeed: 3000,
          autoplaySpeed: 3000,
          loop: true,
          autoplay: false,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          animateOut: 'fadeOut',
          //video: true,
          autoHeight: true,
          mouseDrag: false,
        });

        playstop.on('click', 'button', e => {
          const self = $(e.currentTarget);

          if (self.hasClass('js-home-slider-play')) {
            slider.trigger('play.owl.autoplay', [5000]);
          } else {
            slider.trigger('stop.owl.autoplay');
          }
        });
      }
    },

    init() {
      $(document).ready( () => {
        Misc.homePageSlider();
        Misc.removeEmptyPtags();
        Misc.sidenavParentActive();
        Misc.sideNavGrandchildren();
      });

      $(window).on('load', () => {
      });

      $(window).on('resize', () => {
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
